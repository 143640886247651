/* General styles for read-only input fields */
.read-only-input {
    background-color: #f5f5f5;
    border: 1px solid #ced4da;
    color: black;
}

/* Styles for when the read-only input fields are focused (clicked) */
.read-only-input:focus {
    border-color:#ced4da;
    outline: none;
    box-shadow: 0 0 0 0.2rem #f5f5f5;

    background-color: #f5f5f5;
    border: 1px solid #ced4da;
    color: black;
}
