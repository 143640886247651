.table-responsive {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: auto;
}

.sticky-header th,
.sticky-item-header {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    z-index: 2;
    white-space: nowrap;
}

.sticky-item,
.sticky-item-header {
    position: sticky;
    left: 0;
    background-color: #f8f9fa;
    z-index: 3;
    white-space: nowrap;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}
